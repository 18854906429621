import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const AboutUsOurStory = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="Our Story" />
      <p className="mt-8 text-center text-base text-heading lg:mt-12 lg:text-lg">
     Amidst complex challenges, GemEcosystem emerged, driven by a vision to simplify and innovate. With collaborative minds and relentless dedication, we redefine QA standards, empowering teams worldwide. From inception to triumph, our journey embodies the spirit of continuous evolution and excellence.
      </p>
    </div>
  );
};

export default AboutUsOurStory;
