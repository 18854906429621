import learnAutomationFasterIcon from "../../assets/learnAutomationFasterIcon.svg";
import transformToAutomationQaIcon from "../../assets/transformToAutomationQaIcon.svg";
import interestedInLearningAutomationIcon from "../../assets/interestedInLearningAutomationIcon.svg";

export const GemAcademyOfferingsCardData = [
  {
    id: "01",
    icon: learnAutomationFasterIcon,
    heading: "Learn Automation Faster",
    description:
      "Discover accelerated automation learning with GemJAR & GemPyp, a Java & Python-based Test Automation Framework.",
  },
  {
    id: "02",
    icon: transformToAutomationQaIcon,
    heading: "Transform to Automation QA",
    description:
      "Utilize GemJAR & GemPyp, a Java & Python-based Test Automation Framework for seamless transitions from manual to automated testing.",
  },
  {
    id: "03",
    icon: interestedInLearningAutomationIcon,
    heading: "Interested in Learning Automation",
    description:
      "Explore automation mastery with GemEcosystem—an all-in-one QA resource. ",
  },
];

export const contactHereFormData = [
  {
    id: "01-fullName",
    heading: "Full Name *",
    placeholder: "First name & Last name",
  },
  {
    id: "02-emailId",
    heading: "Email ID *",
    type: "email",
    placeholder: "someone@xyz.com",
  },
  {
    id: "03-phoneNumber",
    heading: "Phone Number *",
    type: "tel",
    keyFilter: /^[+]?([0-9]{1,15})?$/,
    placeholder: "+910123456789",
  },
  {
    id: "04-totalExperience",
    heading: "Total Experience  *",
    keyFilter: "pint",
    inputMode: "numeric",
    placeholder: "Experience (in months)",
  },
  {
    id: "05-experienceManual",
    heading: "Experience in Manual QA  *",
    keyFilter: "pint",
    inputMode: "numeric",
    placeholder: "Experience (in months)",
  },
  {
    id: "06-experienceAutomation",
    heading: "Experience in Automation QA  *",
    keyFilter: "pint",
    inputMode: "numeric",
    placeholder: "Experience (in months)",
  },
  {
    id: "07-rateYourself",
    heading: "Rate Yourself in Automation *",
    keyFilter: "pint",
    inputMode: "numeric",
    placeholder: "Rate yourself from 1 to 10",
  },
];
