import React from "react";

const EcosystemFlowCard = ({
  cardNumber,
  heading,
  description,
  cardIconsData,
  isOdd,
}) => {
  return (
    <div className={`relative ${isOdd ? "lg:pl-20" : "lg:pr-20"}`}>
      <p
        className={` text-xl font-semibold text-heading lg:text-2xl  ${
          isOdd && "lg:text-right"
        } `}
      >
        {heading}
      </p>
      <p
        className={`mt-2 text-base text-bodyText lg:text-xl ${
          isOdd && "lg:text-right"
        } `}
      >
        {description}
      </p>
      <div
        className={`mt-6 flex flex-wrap gap-4 lg:mt-14 ${
          isOdd && " lg:justify-end"
        }`}
      >
        {cardIconsData?.map((item) => {
          return (
            <div className="flex w-28 flex-col  items-center gap-2">
              <img src={item.icon} alt={item.title} loading="lazy" />
              <p className="text-center text-bodyText">{item.title}</p>
            </div>
          );
        })}
      </div>
      <p
        className={`absolute  top-0 -z-10  -translate-y-[40%] text-9xl font-semibold text-[#F1F5FB] lg:px-36 lg:text-[15rem] ${
          isOdd ? "right-0 lg:left-0" : "right-0"
        }`}
      >
        {cardNumber}
      </p>
    </div>
  );
};

export default EcosystemFlowCard;
