import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import gemEcosystemWhiteLogoDesktop from "../../assets/gemEcosystemWhiteLogoDesktop.svg";
import gemEcosystemWhiteLogoWithName from "../../assets/gemEcosystemWhiteLogoWithName.svg";
import crossIcon from "../../assets/crossIcon.svg";
import plusIcon from "../../assets/plusIcon.svg";
import heartIcon from "../../assets/heartIcon.svg";
import {
  footerIcons,
  footerLinksData,
} from "../../helper/cardsData/homepageMockData";
import "./footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  // to make it responsive for larger screens. we hav set max-width 1440 px all over the website.
  // because of the complex structure of the footer
  // I created 2 absolute divs. left and right of the footer respectively.
  // This piece of code calculates the required width for the absolute divs
  const footerRef = useRef();
  const [absoluteRightBackgroudWidth, setAbsoluteRightBackgroundWidth] =
    useState(0);
  const [absoluteLeftBackgroudWidth, setAbsoluteLeftBackgroundWidth] =
    useState(0);

  function updateAbsoluteBackgroundsWidth() {
    const footerRect = footerRef.current?.getBoundingClientRect();
    const width = Math.max(0, (footerRect?.width - 1440) / 2);
    setAbsoluteRightBackgroundWidth(width * 1.02); //increased width by 2 percent. because a white vertical line was showing meaning width is not enough. IDK why this happened. Simple hack
    setAbsoluteLeftBackgroundWidth(width);
  }
  useEffect(() => {
    updateAbsoluteBackgroundsWidth();
    window.addEventListener("resize", updateAbsoluteBackgroundsWidth);
    return () => {
      window.removeEventListener("resize", updateAbsoluteBackgroundsWidth);
    };
  }, []);
  return (
    <footer>
      {/* -------------Mobile Footer----------- */}
      <div className="accordion-wrapper text-white lg:hidden">
        {footerLinksData.map((accordionItem) => {
          return (
            <React.Fragment key={accordionItem.id}>
              <Accordion
                expandIcon={
                  <img loading="lazy" src={plusIcon} alt="Expand Accordion" />
                }
                collapseIcon={
                  <img
                    loading="lazy"
                    src={crossIcon}
                    alt="Collapse Accordion"
                  />
                }
              >
                <AccordionTab
                  className="footer-accordion"
                  key={accordionItem.id}
                  header={accordionItem.heading}
                >
                  {accordionItem.children.map((linkItem) => {
                    return (
                      <p className="mt-3" key={linkItem.id}>
                        <Link to={linkItem.link}>{linkItem.heading}</Link>
                      </p>
                    );
                  })}
                </AccordionTab>
              </Accordion>
              <hr className="footer-hr " />
            </React.Fragment>
          );
        })}

        <div className=" mt-8  flex flex-col items-center justify-center gap-y-3">
          <Link>
            <img
              loading="lazy"
              className="h-8"
              src={gemEcosystemWhiteLogoWithName}
              alt="GemEcosystem"
            />
          </Link>
          <p className="flex items-center text-sm font-medium">
            Made with{" "}
            <img loading="lazy" className="mx-1" src={heartIcon} alt="love" />{" "}
            in Bharat
          </p>
          <div className="flex items-center gap-x-5">
            {footerIcons.map((item) => {
              return (
                <Link key={item.id} to={item.link} target="_blank">
                  <img loading="lazy" src={item.icon} alt={item.id} />
                </Link>
              );
            })}
          </div>
        </div>
        <hr className="footer-hr my-9 " />
        <p className="text-center text-sm font-light">
          All rights reserved. Copyrights 2023
        </p>
      </div>

      {/* ------------Desktop Footer--------- */}
      <div ref={footerRef} className="relative">
        <div
          style={{ width: absoluteRightBackgroudWidth }}
          className="absolute left-0 z-10 h-full bg-[#2180CA] "
        />
        <div
          style={{ width: absoluteLeftBackgroudWidth }}
          className="absolute right-0 z-10 h-full bg-curiousBlue/10 "
        />

        <div className="mx-auto hidden max-w-screen-2xl lg:block ">
          <div className="flex h-96 bg-curiousBlue/10 bg-opacity-100 ">
            <div className=" footer-background flex w-[40%] items-center  ">
              <div className="flex flex-col gap-y-4 pl-16 text-white">
                <Link to="/">
                  <img
                    loading="lazy"
                    className="h-8"
                    src={gemEcosystemWhiteLogoDesktop}
                    alt="GemEcosystem"
                  />
                </Link>
                <p className="flex items-center text-sm font-medium">
                  Made with{" "}
                  <img
                    loading="lazy"
                    className="mx-1"
                    src={heartIcon}
                    alt="love"
                  />{" "}
                  in Bharat
                </p>
                <div className="flex items-center gap-x-5">
                  {footerIcons.map((item) => {
                    return (
                      <Link key={item.id} to={item.link} target="_blank">
                        <img
                          loading="lazy"
                          className="social-media-icons"
                          src={item.icon}
                          alt={item.id}
                        />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="relative flex w-[60%] items-center ">
              <div className="flex w-full justify-evenly pr-16">
                {footerLinksData.map((item) => {
                  return (
                    <div key={item.id}>
                      <p className="footer-links-heading mb-4">
                        {item.heading}
                      </p>
                      {item.children.map((child) => {
                        return (
                          <p key={child.id} className=" mt-3">
                            <Link
                              to={
                                //either env is prod OR beta env Link does not exist
                                process.env.REACT_APP_ENV === "prod" ||
                                !child.betaLink
                                  ? child.link
                                  : child.betaLink
                              }
                              target={child.openInNewTab && "_blank"}
                            >
                              {child.heading}
                            </Link>
                          </p>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <p className="absolute bottom-0 right-0 mb-8 mr-16 text-sm text-heading">
                {`All rights reserved. Copyright© ${currentYear}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
