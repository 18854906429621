import { Button } from "primereact/button";
import React from "react";
import "./StreamlinedQaSolutions.css";
import { Link } from "react-router-dom";
import { carouselItems } from "../../../helper/cardsData/homepageMockData";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const StreamlinedQASolutions = () => {
  return (
    <Carousel
      showThumbs={false}
      interval={5000}
      autoPlay={true}
      infiniteLoop={true}
    >
      {carouselItems.map((item, index) => (
        <div className={`${item.backgroundClasses} component-padding bg-no-repeat bg-center bg-cover text-white `} key={index}>
          <div className=" text-left py-40 max-h-full max-w-full">
            <p className="text-3xl lg:text-5xl">{item.title}</p>
            <p className="mt-3  text-lg lg:text-2xl">
              {item.description}
            </p>
            <Link
              to={
                process.env.REACT_APP_ENV === "prod"
                  ? "https://gemecosystem.com/#/gem-consultancy#contact-for-resources"
                  : "https://beta.gemecosystem.com/#/gem-consultancy#contact-for-resources"
              }
              target="_blank"
            >
              <Button
                className={`base-button landing-page-hero-section-button-margin-top ${item.buttonClassName}`}
                label={item.buttonLabel}
              />
            </Link>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default StreamlinedQASolutions;
