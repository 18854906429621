import { Button } from "primereact/button";
import React from "react";
import { Link } from "react-router-dom";
import ourGemsGemecosystemLogo from "../../../assets/ourGemsGemecosystemLogo.svg";
import rightArrowIcon from "../../../assets/rightArrowIcon.svg";
import { ourGemsData } from "../../../helper/cardsData/homepageMockData";
import "./OurGems.css";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const OurGems = () => {
  //gem for mobile view
  const CustomGemMobile = ({
    gemIcon,
    gemName,
    gemColor,
    gemDescription,
    link,
  }) => {
    return (
      <div className="mt-16">
        <div className="flex items-center justify-center gap-x-2">
          <img loading="lazy" src={gemIcon} alt={gemName} />
          <p className="H6" style={{ color: gemColor }}>
            {gemName}
          </p>
        </div>
        <p className="gem-description-mobile mt-2">{gemDescription}</p>
        <div className="mt-3 flex justify-center">
          <Link to={link} target="_blank">
            <Button className="base-button  primary-button">
              <img
                loading="lazy"
                src={rightArrowIcon}
                alt={`click to open ${gemName}`}
              />
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  // gem for desktop view
  const CustomGemDesktop = ({
    gemIcon,
    gemName,
    gemColor,
    gemDescription,
    gemPosition,
    link,
  }) => {
    return (
      <Link to={link} target="_blank">
        <div className={`gem-desktop-wrapper ${gemPosition}`}>
          <div
            className={`gem-icon-wrapper rounded-full border-[0.5rem] `}
            style={{ borderColor: gemColor }}
          >
            <img
              loading="lazy"
              className=" h-14 w-14"
              src={gemIcon}
              alt={gemName}
            />
          </div>
          <p className="gem-name" style={{ color: gemColor }}>
            {gemName}
          </p>
          <div className="gem-description">{gemDescription}</div>
        </div>
      </Link>
    );
  };

  return (
    <div className="mx-auto max-w-screen-2xl">
      <div className="component-padding ">
        <ComponentHeading heading="Our Gems" />

        {/* ------------Mobile View start---------- */}
        <div className="lg:hidden">
          {ourGemsData.map((gem) => {
            return (
              <CustomGemMobile
                key={gem.id}
                gemIcon={gem.icon}
                gemName={gem.name}
                gemColor={gem.color}
                gemDescription={gem.description}
                link={
                  //either env is prod OR beta env Link does not exist
                  process.env.REACT_APP_ENV === "prod" || !gem.betaLink
                    ? gem.link
                    : gem.betaLink
                }
              />
            );
          })}
        </div>
        {/* ------------Mobile View end---------- */}

        <div className="our-gems-wrapper mt-8 hidden lg:block">
          <div className="our-gems-eclipse" />
          <div className="gemEcosystem-logo-wrapper flex items-center justify-center">
            <div className="logo-wrapper flex items-center justify-center">
              <img
                loading="lazy"
                src={ourGemsGemecosystemLogo}
                alt="GemEcosystem Logo"
              />
            </div>
          </div>
          {ourGemsData.map((gem) => {
            return (
              <CustomGemDesktop
                key={gem.id}
                gemIcon={gem.icon}
                gemName={gem.name}
                gemColor={gem.color}
                gemDescription={gem.description}
                gemPosition={gem.position}
                link={
                  //either env is prod OR beta env Link does not exist
                  process.env.REACT_APP_ENV === "prod" || !gem.betaLink
                    ? gem.link
                    : gem.betaLink
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OurGems;
