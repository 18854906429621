import React from "react";
import HowItWorksHeroSection from "../../components/howItWorks/howItWorksHeroSection/HowItWorksHeroSection";
import HowItWorksSection from "../../components/howItWorks/howItWorksSection/HowItWorksSection";
import EcosystemFlow from "../../components/howItWorks/ecosystemFlow/EcosystemFlow";

const HowItWorks = () => {
  return (
    <>
      <HowItWorksHeroSection />
      <EcosystemFlow />
      <HowItWorksSection />
    </>
  );
};

export default HowItWorks;
