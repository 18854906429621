import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { Carousel } from "primereact/carousel";
import TestimonialsCard from "../testimonials/testimonialsCard/TestimonialsCard";
import {
  carouselItems,
} from "../../../helper/cardsData/AboutUsMockData";


const Testimonials = () => {
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const CarouselKeyFeatureCard = ({ image,icon,description,name,signature }) => {
    return (
      <div className="md:p-2">
        <TestimonialsCard
          image={image}
          icon={icon}
          description={description}
          name={name}
          signature={signature}
          descriptionClasses="lg:h-52 h-96"
        />
      </div>
    );
  };
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <div className="mt-8 lg:mt-12 lg:mb-12 mb-8">
        <Carousel
          value={carouselItems}
          itemTemplate={CarouselKeyFeatureCard}
          numVisible={1}
          circular
          autoplayInterval={3000}
        />
      </div>
    </div>
  );
};

export default Testimonials;
