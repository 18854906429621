import React, { useEffect, useState } from "react";
import "./TestimonialsCard.css";

/**
 * KeyFeatureCard Component
 *
 * @param {string} image - The heading of the card.
 * @param {string} icon - The icon source for the card.
 * @param {string} description - The description of the card.
 * @param {string} name - The name of the card.
 * @param {string} signature - The signature of the card.
 * @param {string} [headingClasses] - Optional additional classes for the heading.
 * @param {string} [descriptionClasses] - Optional additional classes for the description.
 *
 * @returns {JSX.Element} - The KeyFeatureCard component.
 */
const TestimonialsCardDesktop = ({
  image,
  icon,
  description,
  name,
  signature,
  headingClasses,
  descriptionClasses,
}) => {
  return (
    <div className=" testimonials-inner-cards-wrapper mx-2 rounded-2xl">
      <img
        className="object-contain lg:w-3/12"
        src={image}
        alt={name}
        loding="lazy"
      />
      <div className="testimonials-content-wrapper px-7 py-6 lg:w-9/12">
        <img src={icon} className="h-14 w-14" alt={name} loding="lazy" />
        <p
          className={`text-base font-normal text-bodyText lg:mb-6 lg:mt-6 ${
            descriptionClasses && descriptionClasses
          }`}
        >
          {description}
        </p>
        <p className="text-xl font-bold text-heading ">{name}</p>
        <p className="text-lg font-semibold text-bodyText">{signature}</p>
      </div>
    </div>
  );
};

const TestimonialsCardMobile = ({
  image,
  icon,
  description,
  name,
  signature,
  headingClasses,
  descriptionClasses,
}) => {
  return (
    <div className=" testimonials-inner-cards-wrapper mx-2 rounded-md px-4 py-6">
      <div className="flex flex-col ">
        <img src={icon} className="mb-4 h-10 w-10" alt={name} loding="lazy" />
        <p
          className={`text-base font-normal text-bodyText ${
            descriptionClasses && descriptionClasses
          }`}
        >
          {description}
        </p>
        <div className="mt-4 flex flex-row ">
          <img
            className="mr-2 h-16 w-16 rounded-full"
            src={image}
            alt={name}
            loding="lazy"
          />
          <div className="flex flex-grow flex-col ">
            <p className="mb-1 text-xl font-bold  text-heading">{name}</p>
            <p className="text-lg font-semibold text-bodyText">{signature}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialsCard = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)"); // Adjust the width as per your breakpoint
    const checkIsMobile = (e) => {
      setIsMobile(e.matches);
    };
    checkIsMobile(mediaQuery); // Check initial state
    mediaQuery.addListener(checkIsMobile); // Add listener for changes
    return () => {
      mediaQuery.removeListener(checkIsMobile); // Remove listener on component unmount
    };
  }, []);

  return isMobile ? (
    <TestimonialsCardMobile {...props} />
  ) : (
    <TestimonialsCardDesktop {...props} />
  );
};

export default TestimonialsCard;
