import TestimonialsSectionAkshayKumarKatihaImage from "../../assets/TestimonialsSectionAkshayKumarKatihaImage.png";
import TestimonialsSectionRajaAroraImage from "../../assets/TestimonialsSectionRajaAroraImage.png";
import TestimonialsSectionSakshiGargImage from "../../assets/TestimonialsSectionSakshiGargImage.png";
import TestimonialsSectionShrutiKukrejaImage from "../../assets/TestimonialsSectionShrutiKukrejaImage.png";
import TestimonialsSectionShrutiSinghImage from "../../assets/TestimonialsSectionShrutiSinghImage.png";
import TestimonialsIcon from "../../assets/TestimonialsIcon.svg";

export const OurCoreValuesCardData = [
  {
    id: "01",
    heading: "Simplicity in Complexity",
    subHeading: "Simplify to Amplify",
    description:
      "Streamlining QA complexities, our tools make testing intuitive, enabling focus on high-quality software delivery.",
  },
  {
    id: "02",
    heading: "Collaborative Excellence",
    subHeading: "Together towards Triump",
    description:
      "By fostering teamwork, diverse talents synergize for superior quality assurance achievements.",
  },
  {
    id: "03",
    heading: "Continuous Innovation",
    subHeading: "Innovate. Evolve. Excel.",
    description:
      "Innovating QA methodologies, we lead the way with cutting-edge solutions, ensuring users stay ahead in their testing endeavors.",
  },
];

export const carouselItems = [
  {
    image: TestimonialsSectionShrutiKukrejaImage,
    icon: TestimonialsIcon,
    description: "GemEcosystem has truly revolutionized our workflow. With its robust structure and seamless integration capabilities, we've experienced a significant boost in productivity and efficiency. Our team can now focus more on innovative tasks and strategic initiatives, rather than repetitive manual processes. The automation framework has not only saved us time but has also ensured accuracy and reliability in our operations. The customization of reporting feature is an added bonus, providing us with insightful analyses and visually compelling data representations. GemEcosystem isn't just a tool – it's a game-changer that has revolutionized the way we work.",
    name: "Shruti Kukreja",
    signature: "Technology Solutions Lead L2",
  },
 
  {
    image: TestimonialsSectionShrutiSinghImage,
    icon: TestimonialsIcon,
    description: "Transitioning from manual to automated processes? GemJar's got you covered. With its Java-based framework, minimal coding efforts, and seamless integration with Jewel, automation just got a whole lot easier. Save time, gain insights, and keep stakeholders informed—all with GemJar. Elevate your automation game with GemJar today!",
    name: "Shruti Singh",
    signature: "Software Engineer L1",
  },
  {
    image: TestimonialsSectionRajaAroraImage,
    icon: TestimonialsIcon,
    description: "My experience with GemEcosystem applications like Jewel is very brief and in that limited experience I can see Jewel as a one-stop destination for all your day-to-day testing needs, be it the comprehensive reporting, test case management or the recently introduced work minutes feature (soon to be migrated into a standalone app). It'd surpass leaps and bounds once the code-less automation also comes into play, which would make work even easier for an individual with not much of prior experience in automation.",
    name: "Raja Arora",
    signature: "Technology Solutions Lead L2",
  },
  {
    image: TestimonialsSectionSakshiGargImage,
    icon: TestimonialsIcon,
    description: "While using GemEcosystem, my team and I felt like that it is in itself a complete package of an innovative automation framework and reporting.It is easy to integrate and use. The GemEcosystem team is always open to the feedback/suggestions.",
    name: "Sakshi Garg",
    signature: "Technology Solutions Lead L1",
  },
  {
    image: TestimonialsSectionAkshayKumarKatihaImage,
    icon: TestimonialsIcon,
    description: "Jewel has helped the QE team as the best reporting application. Executing manual test cases directly from the ELab and the option to generate the report for the same saves a lot of time.",
    name: "Akshay kumar Katiha",
    signature: "Senior Software Engineer L2",
  },
];
