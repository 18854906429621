import React from "react";
import SignUpMain from "../signUpMain/SignUpMain";
import signupLogo from "../../assets/signUpPageHeadingLogo2.svg";
import "./LoginRoute.css";
import SuccessfullIcon from "../../assets/successfullSignInIcon.svg";
import AgileCrafterIcon from "../../assets/agileCrafterIcon.svg";
import JewelIcon from "../../assets/jewelIcon.svg";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

const LoginRouteHelper = () => {
  return (
    <>
      <SignUpMain>
        <div className="login-wrapper">
          <div>
            <img src={signupLogo} alt="logo" className="mx-auto" />
          </div>
          <div className="customSignup1 flex flex-col gap-20">
            <div className="flex flex-col">
              <img
                className="mx-auto lg:max-w-[15%]"
                src={SuccessfullIcon}
                alt={"SignUp Successfully"}
              />
              <h1 className="mx-auto mt-6 text-3xl font-semibold text-white">
                Congratulations!!
              </h1>
              <p className="mx-auto text-base font-medium text-white mt-2">
                You have successfully signed up for GemEcosystem.
              </p>
            </div>
            <div className="flex flex-col mx-auto gap-6">
            <Link
              to={
                process.env.REACT_APP_ENV === "prod"
                  ? "https://agilecrafter.gemecosystem.com/"
                  : "https://agilecrafter-beta.gemecosystem.com/"
              }
              target="_blank"
            >
              <Button className="base-button custom-btn flex gap-2">
                <img
                  src={AgileCrafterIcon}
                  alt="Button icon"
                  className="p-button-icon"
                />
                <p className="text-base">Login with Agile Crafter</p>
              </Button>
              </Link>
              <Link
              to={
                process.env.REACT_APP_ENV === "prod"
                  ? "https://jewel.gemecosystem.com/#/login"
                  : "https://jewel-beta.gemecosystem.com/#/login"
              }
              target="_blank"
            >
              <Button className="base-button custom-btn flex gap-2 w-full">
                <img
                  src={JewelIcon}
                  alt="Button icon"
                  className="p-button-icon"
                />
                <p className="text-base">Login with Jewel</p>
              </Button>
              </Link>
            </div>
          </div>
        </div>
      </SignUpMain>
    </>
  );
};

export default LoginRouteHelper;
