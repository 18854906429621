import React from "react";
import { howItWorksSectionCardsdata } from "../../../helper/cardsData/howItWorksPageMockData";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const HowItWorksSection = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="How it works?" />
      <div className=" mt-8 flex flex-col items-center gap-20 ">
        {howItWorksSectionCardsdata.map((item) => {
          return (
            <figure key={item.imageDescription}>
              <img src={item.image} alt={item.imageAltText} loading="lazy" />
              <figcaption className=" text-center text-2xl font-semibold">
                {item.imageDescription}
              </figcaption>
            </figure>
          );
        })}
      </div>
    </div>
  );
};

export default HowItWorksSection;
