import elevateSpeedIcon from "../../assets/elevateSpeedIcon.svg";
import redefiningQaEaseIcon from "../../assets/redefiningQaEaseIcon.svg";
import unlockSynergyIcon from "../../assets/unlockSynergyIcon.svg";

import effortlesslyManageImage from "../../assets/effortlesslyManageImage.svg";
import scoringIcon from "../../assets/scoringIcon.svg";
import forEveryoneIcon from "../../assets/forEveryoneIcon.svg";

import footerFacebookIcon from "../../assets/footerFacebookIcon.svg";
import footerInstagramIcon from "../../assets/footerInstagramIcon.svg";
import footerLinkedInIcon from "../../assets/footerLinkedInIcon.svg";
import footerTwitterIcon from "../../assets/footerTwitterIcon.svg";
import footerYouTubeIcon from "../../assets/footerYouTubeIcon.svg";

import gemPypIcon from "../../assets/gemPypIcon.svg";
import gemJarIcon from "../../assets/gemJarIcon.svg";
import gemPrfIcon from "../../assets/gemPrfIcon.svg";
import jewelIcon from "../../assets/jewelIcon.svg";
import gemEcosystemHomePageHeroSectionImage from "../../assets/gemEcosystemHomePageHeroSectionImage.png";
import gemJarHomePageHeroSectionImage from "../../assets/gemJarHomePageHeroSectionImage.png";
import gemPypHomePageHeroSectionImage from "../../assets/gemPypHomePageHeroSectionImage.png";
import jewelHomePageHeroSectionImage from "../../assets/jewelHomePageHeroSectionImage.png";

import headerProductsMenuIcon from "../../assets/headerProductsMenuIcon.svg";
import headerSolutionsMenuIcon from "../../assets/headerSolutionsMenuIcon.svg";
import headerBuildYourFrameworkMenuIcon from "../../assets/headerBuildYourFrameworkMenuIcon.svg";

export const precisionThroughCardsData = [
  {
    id: "01-live-reporting",
    number: "01",
    heading: "Live Reporting",
    description:
      "Run your automation on  your machine or on our Infra (Jewel Infra) and view the Live Execution of progress of automation. Our ML Algorithms predicts the completion time of your test suite execution. Testcases report are available while other testcases are still under execution.",
  },
  {
    id: "02-multiple-framework",
    number: "02",
    heading: "Multiple Framework, One Suite, One Report",
    description:
      "We provide various frameworks tailored for specific purposes. However, the test cases generated from these frameworks can be consolidated into a single test suite. This centralized platform accommodates diverse test cases, including UI, API, manual test cases, and data comparison.",
  },
  {
    id: "03-pipeline",
    number: "03",
    heading: "Pipelines Convergence & Issue Logging",
    description:
      "It integrates with Azure, CI/CD, Jira and Jenkins, empowering QA teams and Business Analysts. This synergy enables efficient resource management, automated application deployment, and seamless collaboration. It's a unified solution that optimizes IT workflows for quality and productivity.",
  },
];

export const carouselItems = [
  {
      // image: gemEcosystemHomePageHeroSectionImage,
      backgroundClasses:"bg-gemEcosystemHomePageHeroSectionImage",
      title: "Streamlined QA Solutions",
      description: "A Single place for all your QA needs",
      buttonLabel: "Schedule Demo",
      buttonClassName: "gemEcosystem-button"
  },
  {
      // image: gemJarHomePageHeroSectionImage,
      backgroundClasses:"bg-gemJarHomePageHeroSectionImage",
      title: "Empowering Testing with Speed and Reliability",
      description: "Web Automation | API Automation | Simplified API Automation",
      buttonLabel: "Schedule Demo",
      buttonClassName: "gemJar-button"
  },
  {
      // image: gemPypHomePageHeroSectionImage,
      backgroundClasses:"bg-gemPypHomePageHeroSectionImage",
      title: " Fast Pace Effortless Automation testing with Reliability ",
      description: " API Migration Testing | Data Comparison | API , UI , Functional Automation",
      buttonLabel: "Schedule Demo",
      buttonClassName: "gemEcosystem-button"
  },
  {
      // image: jewelHomePageHeroSectionImage,
      backgroundClasses:"bg-jewelHomePageHeroSectionImage",
      title: "Illuminating the path to project excellence",
      description: "Jewel is your one stop Dashboard to analyze and visualize data in a unique and efficient way.",
      buttonLabel: "Schedule Demo",
      buttonClassName: "gemEcosystem-button"
  }
];

export const StepIntoEfficiencyCardData = [
  {
    id: "01-elevate",
    icon: elevateSpeedIcon,
    heading: "Elevate Speed",
    briefDescription: "Automation ready in first hour  itself",
    description:
      "With out of box frameworks available, Users can create Automation in 1st hour itself. You just need to focus on writing business logic of testcases because lot of utilities and common things needed are available for you by us",
  },
  {
    id: "02-unlock",
    icon: unlockSynergyIcon,
    heading: "Unlock Synergy",
    briefDescription:
      "Seamless Framework Integration for Comprehensive QA Insight",
    description:
      "Our Frameworks that is our Gems integrates well with each other and external tools to create integrated QA Automation and Reporting Solution which provides 360 degree view of Quality of the Application under Test",
  },
  {
    id: "01-redefining",
    icon: redefiningQaEaseIcon,
    heading: "Simplified Code Automation",
    briefDescription: "From Code To Clarity",
    description:
      "Our platform simplifies UI, REST API, and Database Comparison testing. Create test cases effortlessly using a form-based approach—minimal coding required. Key features include UI recording/playback, intuitive REST API testing, and easy database comparison. Optional code support is available for those who prefer coding.",
  },
];

export const innovationGridCardData = [
  {
    id: "01-effortlessly-manage",
    img: effortlesslyManageImage,
    heading: "Effortlessly Manage 1000s of Tests & Traciability matrix",
    description:
      "Our Solutions like Taskboard , ALab , MLab help you to manage thousands of testcases and their execution , creation of traceability matrix for release with attached testcases , their execution and related defects.",
  },
  {
    id: "02-effortlessly-shape",
    img: scoringIcon,
    heading: "Scoring",
    description:
      "Based on all the test results and automation logs, we product Automation Quality Score and Application Quality Score which help you to know about the health of automation and application. Compare the quality and health across different projects and environments to define the change in state with time.",
  },
  {
    id: "03-update",
    img: forEveryoneIcon,
    heading: "For Everyone- SDETs , SDEs, BAs, PMs",
    description:
      "Our solution and products are build in such a way that not only QAs and SDETs, even Developers and Other Non Coding stackholders can also contribute in automation. Less Code automation helps BA to create and test basic scenario across any environment. API based cloud execution help Devs to shift left and test early and test after deployment in pipeline.",
  },
];

export const footerLinksData = [
  {
    id: "01-getting-started",
    heading: "Getting Started",
    children: [
      {
        id: "01-why",
        heading: "Why Gemecosystem?",
        link: "/how-it-works",
      },
      {
        id: "02-open-api",
        heading: "Open API",
        link: "https://blustone.gemecosystem.com/2024/02/15/open-api/",
        openInNewTab: true,
      },
      {
        id: "03-gem-academy",
        heading: "Gem Academy",
        link: "/gem-academy",
      },
      {
        id: "04-gem-consultancy",
        heading: "Gem Consultancy",
        link: "/gem-consultancy",
      },
    ],
  },
  {
    id: "02-our-offerings",
    heading: "Our Offerings",
    children: [
      {
        id: "01-jewel",
        heading: "Jewel",
        betaLink: "https://jewel-beta.gemecosystem.com/",
        link: "https://jewel.gemecosystem.com/",
        openInNewTab: true,
      },
      {
        id: "02-gem-jar",
        heading: "GemJar",
        betaLink: "https://gemjar-beta.gemecosystem.com/",
        link: "https://gemjar.gemecosystem.com/",
        openInNewTab: true,
      },
      {
        id: "03-gem-pyp",
        heading: "GemPyp",
        betaLink: "https://gempyp-beta.gemecosystem.com/",
        link: "https://gempyp.gemecosystem.com/",
        openInNewTab: true,
      },
      {
        id: "04-gem-prf",
        heading: "GemPrf",
        link: "/gem-prf",
      },
    ],
  },
  {
    id: "04-company",
    heading: "Company",
    children: [
      {
        id: "01-about-us",
        heading: "About Us",
        link: "/about-us",
      },
      {
        id: "02-blogs",
        heading: "Blogs",
        link: "https://blustone.gemecosystem.com/",
        openInNewTab: true,
      },
      {
        id: "03-contact-us",
        heading: "Contact Us",
        link: "/gem-consultancy#contact-for-resources",
      },
      {
        id: "04-working-at",
        heading: "Working at Gemini",
        link: "https://www.geminisolutions.com/life-at-gemini",
        openInNewTab: true,
      },
    ],
  },
];

export const footerIcons = [
  {
    id: "facebook",
    icon: footerFacebookIcon,
    link: "https://www.facebook.com/gemecosys",
  },
  {
    id: "Twitter",
    icon: footerTwitterIcon,
    link: "https://twitter.com/gemecosystem",
  },
  {
    id: "Instagram",
    icon: footerInstagramIcon,
    link: "https://instagram.com/gemecosystem",
  },
  {
    id: "LinkedIn",
    icon: footerLinkedInIcon,
    link: "https://www.linkedin.com/company/gemecosystem",
  },
  {
    id: "youtube",
    icon: footerYouTubeIcon,
    link: "https://youtube.com/@Gemecosystem",
  },
];

export const ourGemsData = [
  {
    id: "01-gemJar",
    icon: gemJarIcon,
    name: "GemJar",
    color: "#307A64",
    description:
      "The Java-based Test Automation Framework offers ready-to-use and customized automation solutions for UI and Backend testing. ",
    position: "gem-position-1",
    betaLink: "https://gemjar-beta.gemecosystem.com/",
    link: "https://gemjar.gemecosystem.com/",
  },
  {
    id: "02-gemPyp",
    icon: gemPypIcon,
    name: "GemPyp",
    color: "#023B59",
    description:
      "GemPyp, a Python-based test automation framework, provides API automation support.",
    position: "gem-position-2",
    betaLink: "https://gempyp-beta.gemecosystem.com/",
    link: "https://gempyp.gemecosystem.com/",
  },
  {
    id: "03-gemPrf",
    icon: gemPrfIcon,
    name: "GemPrf",
    color: "#373163",
    description:
      "GemPrf Plugin for JMeter. It enables users to write load test data on-the-fly to a MongoDB cloud (Gem-Server).",
    position: "gem-position-3",
    betaLink: "/gem-prf",
    link: "/gem-prf",
  },
  {
    id: "04-jewel",
    icon: jewelIcon,
    name: "Jewel",
    color: "#F4736E",
    description:
      "Jewel is an all-in-one QA resource for creating, running, and analyzing test suites with insightful reports.",
    position: "gem-position-4",
    betaLink: "https://jewel-beta.gemecosystem.com/",
    link: "https://jewel.gemecosystem.com/",
  },
];

export const headerLinksData = [
  {
    id: "01-products",
    name: "Products",
    link: "",
    headerLinkMenu: {
      icon: headerProductsMenuIcon,
      heading: "Products",
      description:
        "With our various products, test and manage your modern applications",
      menuChildren: [
        {
          id: "menu-child-a-01",
          headingIcon: jewelIcon,
          heading: "Jewel",
          headingLink: "https://jewel.gemecosystem.com/",
          headingBetaLink: "https://jewel-beta.gemecosystem.com/",
          showExternalLink: true,
          links: [
            {
              name: "Jewel Autolytics",
              betaLink: "https://jewel-beta.gemecosystem.com/#/info/autolytics",
              link: "https://jewel.gemecosystem.com/#/info/autolytics",
              openInNewTab: true,
              showExternalLink: true,
            },
            {
              name: "Jewel Taskboard",
              betaLink: "https://jewel-beta.gemecosystem.com/#/info/taskboard",
              link: "https://jewel.gemecosystem.com/#/info/taskboard",
              openInNewTab: true,
              showExternalLink: true,
            },
            {
              name: "Jewel Testpad",
              betaLink: "https://jewel-beta.gemecosystem.com/#/info/testpad",
              link: "https://jewel.gemecosystem.com/#/info/testpad",
              openInNewTab: true,
              showExternalLink: true,
            },
            {
              name: "Jewel WorkMinutes",
              betaLink:
                "https://jewel-beta.gemecosystem.com/#/info/work-minutes",
              link: "https://jewel.gemecosystem.com/#/info/work-minutes",
              openInNewTab: true,
              showExternalLink: true,
            },
          ],
        },
        {
          id: "menu-child-a-02",
          headingIcon: gemJarIcon,
          heading: "GemJar",
          headingLink: "https://gemjar.gemecosystem.com/",
          headingBetaLink: "https://gemjar-beta.gemecosystem.com/",
          showExternalLink: true,

          links: [
            {
              name: "Gem Heal",
              betaLink: "https://gemjar-beta.gemecosystem.com/#/gem-heal",
              link: "https://gemjar.gemecosystem.com/#/gem-heal",
              openInNewTab: true,
              showExternalLink: true,
            },
          ],
        },
        {
          id: "menu-child-a-03",
          headingIcon: gemPypIcon,
          heading: "GemPyp",
          headingLink: "https://gempyp.gemecosystem.com/",
          headingBetaLink: "https://gempyp-beta.gemecosystem.com/",
          showExternalLink: true,

          links: [
            {
              name: "Pyp DV",
              betaLink: "https://gempyp-beta.gemecosystem.com/#/data-validator",
              link: "https://gempyp.gemecosystem.com/#/data-validator",
              openInNewTab: true,
              showExternalLink: true,
            },
            {
              name: "Pyp Rest",
              betaLink: "https://gempyp-beta.gemecosystem.com/#/pyp-rest",
              link: "https://gempyp.gemecosystem.com/#/pyp-rest",
              openInNewTab: true,
              showExternalLink: true,
            },
          ],
        },
        {
          id: "menu-child-a-04",
          headingIcon: gemPrfIcon,
          heading: "GemPRF",
          // headingLink: "https://gemprf.gemecosystem.com/",
          headingLink: "/gem-prf",
          // headingBetaLink: "https://gemprf-beta.gemecosystem.com/",
          headingBetaLink: "/gem-prf",
          showExternalLink: false,

          links: [
            {
              name: "PRF Autolytics",
              // betaLink: "https://gemprf-beta.gemecosystem.com/#/prf-autolytics",
              betaLink: "/prf-autolytics",
              // link: "https://gemprf.gemecosystem.com/#/prf-autolytics",
              link: "/prf-autolytics",
              openInNewTab: false,
              showExternalLink: false,
            },
            {
              name: "PRF Lab",
              // betaLink: "https://gemprf-beta.gemecosystem.com/#/prf-lab",
              betaLink: "/prf-lab",
              // link: "https://gemprf.gemecosystem.com/#/prf-lab",
              link: "/prf-lab",
              openInNewTab: false,
              showExternalLink: false,
            },
          ],
        },
      ],
    },
  },
  {
    id: "02-solutions",
    name: "Solutions",
    link: "",
    headerLinkMenu: {
      icon: headerSolutionsMenuIcon,
      heading: "Solutions",
      description:
        "With our framework, manage your manual automation in a single regression run",
      menuChildren: [
        {
          id: "menu-child-b-01",
          // headingIcon: jewelIcon,
          heading: "Use Cases",
          headingLink: "",
          links: [
            {
              name: "Simplified API Automation",
              link: "https://blustone.gemecosystem.com/2024/02/01/simplified-api-automation/",
              openInNewTab: true,
              showExternalLink: true,
            },
            {
              name: "Data Comparison",
              link: "https://blustone.gemecosystem.com/2024/02/02/data-comparison-use-cases/",
              openInNewTab: true,
              showExternalLink: true,
            },
            {
              name: "Web UI Automation",
              link: "https://blustone.gemecosystem.com/2024/01/25/web-automation-using-gemjar/",
              openInNewTab: true,
              showExternalLink: true,
            },
            {
              name: "CI CD CT",
              link: "https://blustone.gemecosystem.com/2024/02/02/ct-integration-with-ci-cd/",
              openInNewTab: true,
              showExternalLink: true,
            },
            {
              name: "More Usecases",
              link: "https://blustone.gemecosystem.com/category/usecases/",
              isBlueColor: true,
              openInNewTab: true,
              showExternalLink: true,
            },
          ],
        },
        {
          id: "menu-child-b-02",
          // headingIcon: jewelIcon,
          heading: "For ",
          headingLink: "",
          links: [
            { name: "Large Organizations", link: "/large-organizations" },
            { name: "Startups", link: "/startups" },
            { name: "GemEcosystem Consultancy", link: "/gem-consultancy" },
          ],
        },
        {
          id: "menu-child-b-03",
          // headingIcon: jewelIcon,
          heading: "For Education",
          headingLink: "",
          links: [{ name: "GemEcosystem Academy", link: "/gem-academy" }],
        },
      ],
    },
  },
  {
    id: "03-onboard",
    name: "Onboard to Jewel",
    link: "",
    headerLinkMenu: {
      icon: headerBuildYourFrameworkMenuIcon,
      heading: "Onboard to Jewel",
      description:
        "Jewel: Live reporting, parallel execution, CI/CD integration—efficiency redefined in development",
      menuChildren: [
        {
          id: "menu-child-c-01",
          // headingIcon: jewelIcon,
          heading: "",
          headingLink: "",
          links: [
            {
              name: "Open API",
              link: "https://blustone.gemecosystem.com/2024/02/15/open-api/",
              openInNewTab: true,
            },
          ],
        },
      ],
    },
  },
  {
    id: "04-how-it-works",
    name: "How It Works?",
    link: "/how-it-works",
    hash: "#/how-it-works",
  },
];
