import React from "react";
import comingSoonBackgroundIcon from "../../assets/comingSoonBackgroundIcon.svg";

const ErrorPage = () => {
  return (
    <>
      <div className=" component-padding flex flex-col items-center justify-between ">
        <p className="text-2xl font-light text-heading">Oops!</p>
        <p className="text-center text-5xl font-semibold text-curiousBlue">
          Something went wrong
        </p>
        <img src={comingSoonBackgroundIcon} alt="construction " />
        <p className="text-center text-2xl font-light text-heading">
          We are working on it. Stay Tuned.
        </p>
      </div>
    </>
  );
};

export default ErrorPage;
