import React from "react";
import { GemAcademyOfferingsCardData } from "../../../helper/cardsData/gemAcademyPageMockData";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const GemAcademyOfferings = () => {
  const GemAcademyOfferingsCard = ({ icon, heading, description }) => {
    return (
      <div className="gap-8 lg:flex">
        <div className="flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-2xl border border-hawkesBlue lg:h-32 lg:w-32">
          <img
            className="h-10 w-10 lg:h-16 lg:w-16"
            src={icon}
            alt={heading}
            loading="lazy"
          />
        </div>
        <div className="mt-4 lg:mt-0">
          <p className="text-xl font-semibold text-heading">{heading}</p>
          <p className="mt-2 text-bodyText lg:mt-4">{description}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="mx-auto max-w-screen-2xl">
      <div className="component-padding ">
        <ComponentHeading
          heading="Gem Academy Offerings"
          subHeading=" Learning is fun here!"
        />
        <div className="mt-8 grid gap-6  lg:mt-12 lg:grid-cols-2 lg:gap-8">
          {GemAcademyOfferingsCardData.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <GemAcademyOfferingsCard
                  icon={item.icon}
                  heading={item.heading}
                  description={item.description}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GemAcademyOfferings;
