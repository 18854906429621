import * as yup from "yup";

const FULL_NAME_REGEX =
  /^([0-9A-Za-zàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+)$/;

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PHONE_NUMBER_REGEX = /^[+]?([0-9]{1,18})?$/;

// const SPECIAL_CHAR_PATTERN = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

export const contactHereInitialValues = {
  "01-fullName": "",
  "02-emailId": "",
  "03-phoneNumber": "",
  "04-totalExperience": "",
  "05-experienceManual": "",
  "06-experienceAutomation": "",
  "07-rateYourself": "",
};
export const contactHereValidationSchema = yup.object().shape({
  "01-fullName": yup
    .string()
    .trim()
    .required("First name is required")
    .matches(FULL_NAME_REGEX, "Invalid full name")
    .max(30, "Name must be 30 characters or less"),
  "02-emailId": yup
    .string()
    .required("Email is required")
    .email("Invalid Email Address format")
    .matches(EMAIL_REGEX, "Invalid Email Address format"),
  "03-phoneNumber": yup
    .string()
    .required("Phone Number is required")
    .matches(PHONE_NUMBER_REGEX, "Inavild Phone Number")
    .min(9, "Phone Number is too short")
    .max(15, "Phone Number is too long"),
  "04-totalExperience": yup
    .number()
    .required("Total experience is required")
    .max(1000, "Invalid experience"),
  "05-experienceManual": yup
    .number()
    .required("Manual QA experience is required")
    .max(1000, "Invalid experience"),
  "06-experienceAutomation": yup
    .number()
    .required("Automation QA experience is required")
    .max(1000, "Invalid experience"),
  "07-rateYourself": yup
    .number()
    .required("Your rating is required")
    .max(10, "10 is the maximum rating")
    .min(1, "1 is the minimum rating"),
});

export const contactForResourcesInitialValues = {
  "01-fullName": "",
  "02-emailId": "",
  "03-phoneNumber": "",
  "04-companyName": "",
};
export const contactForResourcesValidationSchema = yup.object().shape({
  "01-fullName": yup
    .string()
    .trim()
    .required("First name is required")
    .matches(FULL_NAME_REGEX, "Invalid full name")
    .max(30, "Name must be 30 characters or less"),
  "02-emailId": yup
    .string()
    .required("Email is required")
    .email("Invalid Email Address format")
    .matches(EMAIL_REGEX, "Invalid Email Address format"),
  "03-phoneNumber": yup
    .string()
    .required("Phone Number is required")
    .matches(PHONE_NUMBER_REGEX, "Inavild Phone Number")
    .min(9, "Phone Number is too short")
    .max(15, "Phone Number is too long"),
  "04-companyName": yup
    .string()
    .trim()
    .required("First name is required")
    .matches(FULL_NAME_REGEX, "Invalid full name")
    .max(50, "Name must be 50 characters or less"),
});
