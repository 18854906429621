import React from "react";
import SinglePlaceForQaLearnings from "../../components/gemAcademy/singlePlaceForQaLearnings/SinglePlaceForQaLearnings";
import GemAcademyOfferings from "../../components/gemAcademy/gemAcademyOfferings/GemAcademyOfferings";
import ContactHere from "../../components/gemAcademy/contactHere/ContactHere";

const GemAcademy = () => {
  return (
    <>
      <SinglePlaceForQaLearnings />
      <GemAcademyOfferings />
      <ContactHere />
    </>
  );
};

export default GemAcademy;
