import React from "react";
import gemConsultancyHeroSectionVector from "../../../assets/gemConsultancyHeroSectionVector.svg";
import RevealOnScroll from "../../../common/revealOnScroll/RevealOnScroll";

const GemConsultancyHeroSection = () => {
  return (
    <div className=" bg-curiousBlue/10 ">
      <div className="mx-auto max-w-screen-2xl">
        <RevealOnScroll>
          <div className="component-padding  lg:flex lg:items-center lg:justify-between ">
            <div className="lg:max-w-[47%] lg:flex-1">
              <p className="text-[2rem] font-semibold text-heading lg:text-[2.5rem] lg:leading-[3.125rem]">
                Need resources to work on Automation for your project?
              </p>
            </div>
            <img
              className="mx-auto lg:mx-0 lg:max-w-[47%] lg:flex-1"
              src={gemConsultancyHeroSectionVector}
              alt={"single Place For Qa Learning "}
            />
          </div>
        </RevealOnScroll>
      </div>
    </div>
  );
};

export default GemConsultancyHeroSection;
