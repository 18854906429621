import React from "react";
import InnovationGrid from "../../components/homePage/innovationGrid/InnovationGrid";
import OurGems from "../../components/homePage/ourGems/OurGems";
import PrecisionThrough from "../../components/homePage/precisionThrough/PrecisionThrough";
import StepIntoEfficiency from "../../components/homePage/stepIntoEfficiency/StepIntoEfficiency";
import StreamlinedQASolutions from "../../components/homePage/streamlinedQaSolutions/StreamlinedQaSolutions";

const Homepage = () => {
  return (
    <>
      <StreamlinedQASolutions />
      <StepIntoEfficiency />
      <PrecisionThrough />
      <OurGems />
      <InnovationGrid />
    </>
  );
};

export default Homepage;
