import { Button } from "primereact/button";
import React from "react";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import RevealOnScroll from "../../../common/revealOnScroll/RevealOnScroll";
import "./HowItWorksHeroSection.css";

const HowItWorksHeroSection = () => {
  return (
    <div className=" bg-[#407EC4]/10 ">
      <div className="mx-auto max-w-screen-2xl">
        <RevealOnScroll>
          <div className=" component-padding lg:grid  lg:grid-cols-2 lg:items-center lg:gap-x-14">
            <div>
              <p className="H5 lg:!text-[2.5rem] lg:!leading-[3.75rem] ">
                <span className="text-heading">Understanding the</span>{" "}
                <span className="text-curiousBlue">Ecosystem and its flow</span>
              </p>
              <p className="mt-3 text-lg text-heading lg:text-2xl ">
                Explore GemEcosystem’s core for innovative efficiency, featuring
                parallel execution.
              </p>
              <Link to="/explore-gemecosystem">
                <Button
                  className="base-button primary-button hover-right-arrow !mt-8"
                  label="Explore GemEcosystem"
                />
              </Link>
            </div>
            <div className=" video-player-wrapper mt-10 aspect-video    lg:mt-0">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=LWJutrj2rgI"
                height="100%"
                width="100%"
                controls
                light
              />
            </div>
          </div>
        </RevealOnScroll>
      </div>
    </div>
  );
};

export default HowItWorksHeroSection;
