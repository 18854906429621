import howItWorksSectionImage1 from "../../assets/howItWorksSectionImage1.svg";
import howItWorksSectionImage2 from "../../assets/howItWorksSectionImage2.svg";
import howItWorksSectionImage3 from "../../assets/howItWorksSectionImage3.svg";

import ecosystemFlowImage1 from "../../assets/ecosystemFlowImage1.svg";
import ecosystemFlowImage2 from "../../assets/ecosystemFlowImage2.svg";
import ecosystemFlowImage3 from "../../assets/ecosystemFlowImage3.svg";

import ecosystemFlowInstallFrameworkIcon from "../../assets/ecosystemFlowInstallFrameworkIcon.svg";
import ecosystemFlowBusinessLogicIcon from "../../assets/ecosystemFlowBusinessLogicIcon.svg";
import ecosystemFlowRunAndDebugIcon from "../../assets/ecosystemFlowRunAndDebugIcon.svg";
import ecosystemFlowIntegrateTokenIcon from "../../assets/ecosystemFlowIntegrateTokenIcon.svg";
import ecosystemFlowExecuteAnywhereIcon from "../../assets/ecosystemFlowExecuteAnywhereIcon.svg";

import ecosystemFlowUiTestCreationIcon from "../../assets/ecosystemFlowUiTestCreationIcon.svg";
import ecosystemFlowSeamlessIntegrationIcon from "../../assets/ecosystemFlowSeamlessIntegrationIcon.svg";
import ecosystemFlowTestcaseManagementIcon from "../../assets/ecosystemFlowTestcaseManagementIcon.svg";
import ecosystemFlowOneClickExecutionIcon from "../../assets/ecosystemFlowOneClickExecutionIcon.svg";
import ecosystemFlowParallelExecutionIcon from "../../assets/ecosystemFlowParallelExecutionIcon.svg";

import ecosystemFlowLiveExecutionViewIcon from "../../assets/ecosystemFlowLiveExecutionViewIcon.svg";
import ecosystemFlowComprehensiveAnalysisIcon from "../../assets/ecosystemFlowComprehensiveAnalysisIcon.svg";
import ecosystemFlowDynamicDashboardsIcon from "../../assets/ecosystemFlowDynamicDashboardsIcon.svg";
import ecosystemFlowAnalyticalParametersIcon from "../../assets/ecosystemFlowAnalyticalParametersIcon.svg";
import ecosystemFlowTransparentHierarchyIcon from "../../assets/ecosystemFlowTransparentHierarchyIcon.svg";
import ecosystemFlowSecureSharingIcon from "../../assets/ecosystemFlowSecureSharingIcon.svg";
import jewelFlowChartImage from "../../assets/jewelFlowChartImage.svg";
import gemPypFlowChartImage from "../../assets/gemPypFlowChartImage.svg";
import gemJarFlowChartImage from "../../assets/gemJarFlowChartImage.svg";

export const howItWorksSectionCardsdata = [
  {
    image: jewelFlowChartImage,
    imageDescription: "Working of Jewel",
    imageAltText: "Working of Jewel",
  },
  {
    image: gemJarFlowChartImage,
    imageDescription: "Working of GemJar",
    imageAltText: "Working of GemJar",
  },
  {
    image: gemPypFlowChartImage,
    imageDescription: "Working of GemPyp",
    imageAltText: "Working of GemPyp",
  },
];

export const ecosystemFlowCardsData = [
  {
    image: ecosystemFlowImage1,
    cardNumber: "1",
    heading: "SetUp Test",
    description:
      "As a QA Manager, you don't have to manage framework development; we provide an out-of-the-box framework for various purposes.",
    cardIconsData: [
      {
        icon: ecosystemFlowInstallFrameworkIcon,
        title: "Install Framework",
      },
      {
        icon: ecosystemFlowBusinessLogicIcon,
        title: "Business Logic",
      },
      {
        icon: ecosystemFlowRunAndDebugIcon,
        title: "Run and Debug",
      },
      {
        icon: ecosystemFlowIntegrateTokenIcon,
        title: "Integrate Token",
      },
      {
        icon: ecosystemFlowExecuteAnywhereIcon,
        title: "Execute Anywhere",
      },
    ],
    showVerticalLineIconBelow: true,
  },
  {
    image: ecosystemFlowImage2,
    cardNumber: "2",
    heading: "Execution - Jewel UI",
    description:
      "With our automation frameworks, automate Web Services without writing a code with 5X less time.",
    cardIconsData: [
      {
        icon: ecosystemFlowUiTestCreationIcon,
        title: "UI Test Creation",
      },
      {
        icon: ecosystemFlowSeamlessIntegrationIcon,
        title: "seamless Integration",
      },
      {
        icon: ecosystemFlowTestcaseManagementIcon,
        title: "Testcase Management",
      },
      {
        icon: ecosystemFlowOneClickExecutionIcon,
        title: "One-Click Execution",
      },
      {
        icon: ecosystemFlowParallelExecutionIcon,
        title: "Parallel Execution",
      },
    ],
    showVerticalLineIconBelow: true,
  },
  {
    image: ecosystemFlowImage3,
    cardNumber: "3",
    heading: "Reporting - Jewel UI",
    description:
      "View what's happening in project, get quality insight using dashboard, reports and score just by few clicks.",
    cardIconsData: [
      {
        icon: ecosystemFlowLiveExecutionViewIcon,
        title: "Live Execution View",
      },
      {
        icon: ecosystemFlowComprehensiveAnalysisIcon,
        title: "Comprehensive Analysis",
      },
      {
        icon: ecosystemFlowDynamicDashboardsIcon,
        title: "Dynamic Dashboards",
      },
      {
        icon: ecosystemFlowAnalyticalParametersIcon,
        title: "Analytical Parameters",
      },
      {
        icon: ecosystemFlowTransparentHierarchyIcon,
        title: "Transparent Hierarchy",
      },
      {
        icon: ecosystemFlowSecureSharingIcon,
        title: "Secure Sharing",
      },
    ],
    showVerticalLineIconBelow: false,
  },
];
