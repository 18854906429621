import React from "react";
import ourCoreValuesBaseIconEven from "../../../../assets/ourCoreValuesBaseIconEven.svg";
import ourCoreValuesBaseIconOdd from "../../../../assets/ourCoreValuesBaseIconOdd.svg";
import ourCoreValuesIcon from "../../../../assets/ourCoreValuesIcon.svg";

export const AboutUsOurCoreValuesCard = ({
  heading,
  subHeading,
  description,
  isOdd,
}) => {
  return (
    <div
      className={` flex flex-col overflow-hidden rounded-3xl border-[0.5px] border-[#d1dae7] ${
        isOdd ? "bg-[#4FA1E0]" : "bg-[#407ec4]"
      }`}
    >
      <div className="p-6 text-white">
        <img
          className="h-24 w-24"
          src={ourCoreValuesIcon}
          alt={heading}
          loading="lazy"
        />
        <p className="mt-6 text-xl font-bold">{heading}</p>
        <p className="mt-1 text-lg font-semibold">{subHeading}</p>
        <p className="mt-4 text-base ">{description}</p>
      </div>
      <div className="mt-auto">
        <img
          src={isOdd ? ourCoreValuesBaseIconEven : ourCoreValuesBaseIconOdd}
          alt="Base Icon"
          className="h-full w-full  object-cover"
        />
      </div>
    </div>
  );
};

export default AboutUsOurCoreValuesCard;
