import React from "react";
import GemConsultancyHeroSection from "../../components/gemConsultancy/gemConsultancyHeroSection/GemConsultancyHeroSection";
import ContactForResources from "../../components/gemConsultancy/contactForResources/ContactForResources";

const GemConsultancy = () => {
  return (
    <>
      <GemConsultancyHeroSection />
      <ContactForResources />
    </>
  );
};

export default GemConsultancy;
