import { toast } from "react-toastify";

/**
 *
 * @param errorMessage send the error message you want to display in the toast
 * @return error toast
 */
export const errorToast = (
  errorMessage = "Something went wrong. Please try again in some time",
) => {
  toast.error(errorMessage, {
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    pauseOnHover: true,
    progress: undefined,
  });
};

/**
 *
 * @param successMessage send the success message you want to display in the toast
 * @returns success toast
 */
export const successToast = (successMessage) => {
  toast.success(successMessage, {
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    pauseOnHover: true,
    progress: undefined,
  });
};
