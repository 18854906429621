import React from "react";
import "./PrecisionThrough.css";
import NumberCard from "../../../common/numberCard/NumberCard";
import { precisionThroughCardsData } from "../../../helper/cardsData/homepageMockData";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const PrecisionThrough = () => {
  return (
    <div className="precision-through-wrapper">
      <div className="mx-auto max-w-screen-2xl">
        <div className="component-padding ">
          <ComponentHeading heading="Precision Through Streamlined QA Practices" />
          <div className="mt-8 flex flex-col gap-y-6 lg:mt-12 lg:flex-row lg:gap-x-6 ">
            {precisionThroughCardsData.map((cardData) => {
              return (
                <NumberCard
                  key={cardData.id}
                  number={cardData.number}
                  heading={cardData.heading}
                  description={cardData.description}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrecisionThrough;
