import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import httpService from "../../services/http.service";
import "./ChangeEmail.css";
import { statementError } from "../../helper/ToastHelper";
import signupLogo from "../../assets/signUpPageHeadingLogo2.svg";
import { toast } from "react-toastify";
import SignUpMain from "../signUpMain/SignUpMain";

const ChangeEmail = () => {
  const navigate = useNavigate();
  const locationState = useLocation().state;
  const firstName = locationState?.firstName ?? "";
  const lastName = locationState?.lastName ?? "";
  const username = locationState?.username ?? "";
  const hashedPassword = locationState?.hashedPassword ?? "";
  const companyName = locationState?.companyName ?? "";

  const [email, setEmail] = useState(locationState?.email ?? "");
  function handleChangeEmail() {
    httpService
      .changeEmail({
        username: username,
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: hashedPassword,
        company: companyName,
      })
      .then(() => {
        // getSuccessToast("Email updated successfully");
        toast.success("Email updated successfully", {
          position: "top-left",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/account-verification", {
          state: {
            firstName: firstName,
            lastName: lastName,
            username: username,
            email: email,
            hashedPassword: hashedPassword,
            companyName: companyName,
          },
        });
      })
      .catch((err) => {
        statementError(err?.response?.data?.message, 5000);
      });
  }

  return (
    <>
       <SignUpMain>
      <div className="my-auto px-9 py-5">
        <div>
          <img src={signupLogo} alt="logo" className="mx-auto" />
        </div>
      <div className="customSignup mt-10">
        <div className="flex flex-col items-center bg-white rounded-lg shadow-lg py-5 px-2 sm:px-4 md:px-5 lg:px-5 change-email-card">
          <h1>Change Email</h1>
          <h2>Please enter new Email</h2>
          <InputText
            id="change-email"
            keyfilter="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@domain.com"
            className="my-3"
          />

          <div className="flex mt-3 justify-center">
            <button
              className="mx-3 rounded-lg bg-blue-500 px-3 py-2 text-white hover:bg-blue-700"
              label="Verify OTP"
              onClick={handleChangeEmail}
              disabled={email.length === 0}
            >
              Change Email
            </button>
          </div>
        </div>
      </div>
      </div>
      </SignUpMain>
      </>
  );
};

export default ChangeEmail;
