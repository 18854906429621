import React from "react";
import { OurCoreValuesCardData } from "../../../helper/cardsData/AboutUsMockData";
import AboutUsOurCoreValuesCard from "./aboutUsOurCoreValuesCard/AboutUsOurCoreValuesCard";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const AboutUsOurCoreValues = () => {
  return (
    <div className="bg-blueGgradientBackground">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Our Core Values" />
        <div className=" mt-8  grid  gap-8 md:grid-cols-2 lg:mt-12 lg:grid-cols-3 ">
          {OurCoreValuesCardData.map((cardData, index) => {
            return (
              <React.Fragment key={cardData.id}>
                <AboutUsOurCoreValuesCard
                  heading={cardData.heading}
                  subHeading={cardData.subHeading}
                  description={cardData.description}
                  isOdd={index % 2 === 1}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AboutUsOurCoreValues;
