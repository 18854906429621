import React from "react";
import EcosystemFlowCard from "./ecosystemFlowCard/EcosystemFlowCard";
import { ecosystemFlowCardsData } from "../../../helper/cardsData/howItWorksPageMockData";
import howItWorksVerticalLineIcon from "../../../assets/howItWorksVerticalLineIcon.svg";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const EcosystemFlow = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="Ecosystem Flow" />
      {ecosystemFlowCardsData.map((item, index) => {
        const isOdd = index % 2 === 1 ? true : false;
        return (
          <div>
            <div
              key={item.heading}
              className={`mt-8 flex flex-col gap-6 lg:mt-20  lg:gap-10 ${
                isOdd ? "lg:flex-row-reverse" : "lg:flex-row"
              }`}
            >
              <div className="flex justify-center lg:w-[40%]">
                <img src={item.image} alt={item.heading} loading="lazy" />
              </div>
              <div className="lg:w-[60%]">
                <EcosystemFlowCard
                  cardNumber={item.cardNumber}
                  heading={item.heading}
                  description={item.description}
                  cardIconsData={item.cardIconsData}
                  isOdd={isOdd}
                />
              </div>
            </div>
            {item.showVerticalLineIconBelow && (
              <div className="mt-8 flex justify-center lg:mt-0">
                <img
                  src={howItWorksVerticalLineIcon}
                  alt="vertical line"
                  loading="lazy"
                />
              </div>
            )}
          </div>
        );
      })}
      <EcosystemFlowCard />
    </div>
  );
};

export default EcosystemFlow;
