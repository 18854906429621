import React from "react";
import comingSoonBackgroundIcon from "../../assets/comingSoonBackgroundIcon.svg";

const ComingSoon = () => {
  return (
    <div className=" component-padding flex flex-col items-center justify-between ">
      <p className="mb-2 text-2xl font-light text-heading">Hold Tight !</p>
      <p className=" text-center text-5xl font-semibold text-curiousBlue">
        This Page is Coming Soon
      </p>
      <img src={comingSoonBackgroundIcon} alt="coming soon " />

      <p className=" text-center text-2xl font-light text-heading">
        Our scouts are currently working hard to build this page !
      </p>
    </div>
  );
};

export default ComingSoon;
