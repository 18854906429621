import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { Link } from "react-router-dom";
import downArrowIcon from "../../../assets/downArrowIcon.svg";
import upArrowIcon from "../../../assets/upArrowIcon.svg";
import { headerLinksData } from "../../../helper/cardsData/homepageMockData";
import "./mobileNavDrawer.css";
import jewelIcon from "../../../assets/jewelIcon.svg";
import CustomExternalLinkIcon from "../../customExternalLinkIcon/CustomExternalLinkIcon";

const MobileNavDrawer = ({ open, setOpen }) => {
  // scroll to top & disbale scroll if open.
  // Because previously user could scroll and navBar got out of the view
  // if (open) {
  //   window.scrollTo(0, 0);
  //   document.body.style.overflow = "hidden";
  // } else {
  //   document.body.style.overflow = "auto";
  // }

  const AccordionOptionsHeading = ({ headingIcon, heading }) => {
    return (
      <div className="mt-4 flex items-center gap-x-1">
        {headingIcon && (
          <div className="h-7 w-7">
            <img src={headingIcon} alt={heading} />
          </div>
        )}
        <p className="header-link-menu-content-heading ">{heading}</p>
      </div>
    );
  };

  return (
    <Sidebar position="right" visible={open} onHide={() => setOpen(false)}>
      <div className="mt-4">
        {headerLinksData.map((item) => {
          return (
            <React.Fragment key={item.id}>
              {item.name === "How It Works?" ? (
                <div className="mt-6">
                  <Link
                    to={item.link}
                    className="text-lg font-semibold text-heading   "
                  >
                    How It Works?
                  </Link>
                </div>
              ) : (
                <>
                  <Accordion
                    expandIcon={
                      <img
                        loading="lazy"
                        src={downArrowIcon}
                        alt="Expand Accordion"
                      />
                    }
                    collapseIcon={
                      <img
                        loading="lazy"
                        src={upArrowIcon}
                        alt="Collapse Accordion"
                      />
                    }
                  >
                    <AccordionTab
                      className="mobile-navbar-drawer   "
                      header={item.name}
                    >
                      {item?.headerLinkMenu?.menuChildren.map((child) => {
                        return (
                          <div key={child.id}>
                            <div className="w-fit">
                              {child.headingLink || child.headingBetaLink ? (
                                <Link
                                  to={
                                    //either env is prod OR beta env Link does not exist
                                    process.env.REACT_APP_ENV === "prod" ||
                                    !child.headingBetaLink
                                      ? child.headingLink
                                      : child.headingBetaLink
                                  }
                                  target="_blank"
                                >
                                  <AccordionOptionsHeading
                                    headingIcon={child.headingIcon}
                                    heading={child.heading}
                                  />
                                </Link>
                              ) : (
                                <AccordionOptionsHeading
                                  headingIcon={child.headingIcon}
                                  heading={child.heading}
                                />
                              )}
                            </div>

                            <div className="mt-2">
                              {child.links.map((childLink, index) => {
                                return (
                                  <p key={index} className="ml-8 mt-1">
                                    <Link
                                      to={
                                        //either env is prod OR beta env Link does not exist
                                        process.env.REACT_APP_ENV === "prod" ||
                                        !childLink.betaLink
                                          ? childLink.link
                                          : childLink.betaLink
                                      }
                                      target={
                                        childLink.openInNewTab
                                          ? "_blank"
                                          : "_self"
                                      }
                                      className="flex items-center"
                                    >
                                      <p
                                        className={`header-link-menu-content-link ${
                                          childLink.isBlueColor &&
                                          "!text-curiousBlue"
                                        }`}
                                      >
                                        {childLink.name}
                                      </p>
                                      {childLink.showExternalLink && (
                                        <CustomExternalLinkIcon
                                          fillColor={
                                            childLink.isBlueColor
                                              ? "#407ec4"
                                              : "#5b5b5b"
                                          }
                                        />
                                      )}
                                    </Link>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </AccordionTab>
                  </Accordion>
                  <hr className="footer-hr " />{" "}
                </>
              )}
            </React.Fragment>
          );
        })}
        <div className="mt-8 flex gap-x-6">
          <Link
            to={
              process.env.REACT_APP_ENV === "prod"
                ? "https://jewel.gemecosystem.com/#/signup"
                : "https://jewel-beta.gemecosystem.com/#/signup"
            }
            className="flex-1"
          >
            <Button
              label="Sign up"
              title="Sign up with Jewel"
              className="base-button secondary-button h-full w-full "
              // icon={
              //   <img
              //     src={jewelIcon}
              //     className="mr-2 h-6 w-6 "
              //     alt="sign up with jewel"
              //   />
              // }
              iconPos="left"
            />
          </Link>
          <Link
            to={
              process.env.REACT_APP_ENV === "prod"
                ? "https://jewel.gemecosystem.com/#/login"
                : "https://jewel-beta.gemecosystem.com/#/login"
            }
            className="flex-1"
          >
            <Button
              label="Login"
              title="Login with Jewel"
              className="base-button primary-button h-full w-full"
              // icon={
              //   <img
              //     src={jewelIcon}
              //     className="mr-2 h-6 w-6 "
              //     alt="login with jewel"
              //   />
              // }
              iconPos="left"
            />
          </Link>
        </div>
      </div>
    </Sidebar>
  );
};

export default MobileNavDrawer;
