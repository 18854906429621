export const contactForResourcesFormData = [
  {
    id: "01-fullName",
    heading: "Full Name *",
    placeholder: "First name & Last name",
  },
  {
    id: "02-emailId",
    heading: "Email ID *",
    type: "email",
    placeholder: "someone@xyz.com",
  },
  {
    id: "03-phoneNumber",
    heading: "Phone Number *",
    type: "tel",
    keyFilter: /^[+]?([0-9]{1,15})?$/,
    placeholder: "+910123456789",
  },
  {
    id: "04-companyName",
    heading: "Company Name  *",
    placeholder: "Gemini Solutions pvt ltd",
  },
];
