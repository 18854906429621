import { toast } from "react-toastify";
export const handleToastErrorCatch = (err, fn) => {
  toast.error(
    err?.response?.data?.message
      ? err.response.data.message
      : `Something Went Wrong. 
                Please refresh the Page / Try Again`,
    {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      className: "mt-5",
    },
  );
  if (fn && typeof fn === "function") {
    fn();
  }
};

/**
 * Function to display an error toast message.
 * @param {string} errorMessage - The error message to display.
 * @param {number} autoCloseTime - Optional. The time in milliseconds before the toast automatically closes.
 */
export const statementError = (
  errorMessage = "Something went wrong",
  autoCloseTime = 3000,
) => {
  toast.error(errorMessage, {
    position: "top-right",
    autoClose: autoCloseTime,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    className: "mt-5",
  });
};

/**
 * Function to display a success toast message.
 * @param {string} successMessage - The success message to display.
 * @param {number} autoCloseTime - Optional. The time in milliseconds before the toast automatically closes.
 */
export const getSuccessToast = (successMessage, autoCloseTime = 3000) => {
  toast.success(successMessage, {
    position: "relative",
    autoClose: autoCloseTime,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    className: "mt-5",
  });
};
/**
 * Function to display a info toast message.
 * @param {string} infoMessage - The info message to display.
 * @param {number} autoCloseTime - Optional. The time in milliseconds before the toast automatically closes.
 */
export const getInfoToast = (infoMessage, autoCloseTime = 3000) => {
  toast.info(infoMessage, {
    position: "top-right",
    autoClose: autoCloseTime,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    className: "mt-5",
  });
};
export const getInfoMessage = (data) => {
  if (data["operation"] && data["operation"].toLowerCase() === "success") {
    toast.info(data["message"], {
      position: "top-right",

      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
};
export const getToastMessage = (data) => {
  const message =
    data?.operation.toLowerCase() === "success"
      ? toast.success(data["message"], {
          position: "top-right",

          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      : toast.error(data["message"], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
  return message;
};
export const getErrorMessage = (data) => {
  const message =
    data["operation"] && data["operation"].toLowerCase() === "failure"
      ? toast.error(data["message"], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })
      : null;
  return message;
};
