import React from "react";
import "./NumberCard.css";

const NumberCard = ({ number, heading, description }) => {
  return (
    <div className="number-card-wrapper lg:flex-1">
      <div className="flex items-center gap-x-3 lg:min-h-[4rem]">
        <div className="H5 text-curiousBlue lg:!text-4xl  ">{number}</div>
        <div className="text-xl font-semibold lg:text-2xl">{heading}</div>
      </div>
      <hr className="customHr my-3 lg:my-5 " />
      <div className="number-card-description">{description}</div>
    </div>
  );
};

export default NumberCard;
