import { Formik } from "formik";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useRef } from "react";
import contactForResourcesVector from "../../../assets/contactForResourcesVector.svg";
import { contactForResourcesFormData } from "../../../helper/cardsData/gemConsultancyPageMockData";
import {
  contactForResourcesInitialValues,
  contactForResourcesValidationSchema,
} from "../../../helper/formValidations";
import "react-toastify/dist/ReactToastify.css";
import { addDoc, collection } from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../../../firebase";
import { errorToast, successToast } from "../../../utils/toast";
import ReCAPTCHA from "react-google-recaptcha";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const ContactForResources = () => {
  const isCaptchaVerifiedRef = useRef(false);

  async function submitFunction(values, { resetForm }) {
    if (!isCaptchaVerifiedRef.current) {
      errorToast("Please verify the reCAPTCHA");
      return;
    }

    try {
      await addDoc(collection(db, "gemConsultancy"), {
        values: values,
        env: window.location.origin,
        date: new Date(),
      });
      successToast("Thank You. We will contact you shortly");
      resetForm();
    } catch (e) {
      errorToast();
      console.error("Error adding form data: ", e);
    }
  }
  return (
    <div id="contact-for-resources" className="contact-here-bg  ">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Contact For Resources" />
        <div className="mt-8 lg:flex lg:items-center lg:justify-between ">
          <div className=" lg:max-w-[47%] lg:flex-1">
            <Formik
              initialValues={contactForResourcesInitialValues}
              validationSchema={contactForResourcesValidationSchema}
              onSubmit={submitFunction}
            >
              {({
                touched,
                errors,
                values,
                isValid,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <form
                  className="grid gap-8 lg:grid-cols-2"
                  onSubmit={handleSubmit}
                >
                  {contactForResourcesFormData.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="relative flex flex-col gap-2 lg:justify-between lg:first:col-span-2"
                      >
                        <label
                          htmlFor={item.heading}
                          className=" font-medium text-heading"
                        >
                          {item.heading}
                        </label>

                        <InputText
                          id={item.id}
                          className={
                            touched[item.id] && errors[item.id] && "p-invalid"
                          }
                          placeholder={item.placeholder}
                          type={item.type}
                          keyfilter={item.keyFilter}
                          value={values[item.id]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          aria-describedby={item.placeholder}
                        />

                        {touched[item.id] && errors[item.id] && (
                          <small
                            id={item.placeholder}
                            className="absolute bottom-0 translate-y-full text-sm text-fireBrick "
                          >
                            {errors[item.id]}
                          </small>
                        )}
                      </div>
                    );
                  })}

                  <ReCAPTCHA
                    className="lg:col-span-2"
                    onChange={(e) => {
                      if (e) {
                        isCaptchaVerifiedRef.current = true;
                      } else {
                        isCaptchaVerifiedRef.current = false;
                      }
                    }}
                    sitekey={
                      process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_DATA_SITE_KEY
                    }
                  />
                  <Button
                    className="base-button primary-button !mr-auto"
                    label={isSubmitting ? "Submitting" : "Submit"}
                    type="Submit"
                    loading={isSubmitting}
                    icon={"pi pi-spin pi-spinner"}
                    iconPos="right"
                    disabled={!isValid}
                  />
                </form>
              )}
            </Formik>
          </div>
          <div className="mt-10 lg:mx-0 lg:mt-0 lg:max-w-[47%] lg:flex-1">
            <img
              loading="lazy"
              className="mx-auto lg:max-h-fit"
              src={contactForResourcesVector}
              alt={"Contact For Resources"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForResources;
