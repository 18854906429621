import React from "react";
import "./AboutUsHeroSection.css";
import aboutUsHeroSectionImage from "../../../assets/aboutUsHeroSectionImage.png";

const AboutUsHeroSection = () => {
  return (
    <div className="about-us-hero-section-background">
      <div className="component-padding mx-auto max-w-screen-2xl ">
        <div className="text-[2rem] font-bold leading-normal lg:text-center   lg:text-[2.5rem] lg:font-semibold lg:leading-[3.125rem] ">
          <p className="text-heading">GemEcosystem: </p>
          <p className="text-curiousBlue">
            Empowering Quality Assurance Innovation
          </p>
        </div>
        <p className="mt-3 text-lg text-heading  lg:mt-8 lg:text-center  ">
          In a dynamic landscape, GemEcosystem pioneers QA innovation. Through
          simplicity, collaboration, innovation, and customer-centricity, we
          empower teams to redefine quality assurance, driving success and
          excellence in every endeavor.
        </p>
        <img
          className="mx-auto mt-8 lg:mt-20 "
          src={aboutUsHeroSectionImage}
          alt="photo of the gemecosystem team"
        />
      </div>
    </div>
  );
};

export default AboutUsHeroSection;
