import React from 'react'
import './SignUpMain.css'
import signupImage from "../../assets/signUpPageImage.svg";
import upperRing from "../../assets/signUpBackgroundRing.svg";
import bottomRing from "../../assets/signUpBackgroundRings.svg";

const SignUpMain = (props) => {
  return (
    <>
    <div className="signUp-background-wrapper">
      <div className="upper-ring hidden lg:block md:block">
        <img src={upperRing} alt="logo" />
      </div>
      <div className="bottom-ring hidden lg:block md:block">
        <img src={bottomRing} alt="logo" />
      </div>
      <div className="mx-auto h-screen grid max-w-screen-2xl grid-cols-1 lg:grid-cols-2 md:grid-cols-2 ">
      <div className="mx-auto flex justify-center items-center h-screen">{props.children}</div>
      <div className="hidden  lg:my-auto lg:block md:block lg:w-3/4 md:w-3/4 lg:mr-0 lg:ml-auto">
            <img src={signupImage} alt="logo" className="h-full w-full lg:mr-0" />
          </div>
      </div>
      </div>
    </>
  )
}

export default SignUpMain