import React from "react";
import AboutUsHeroSection from "../../components/aboutUs/aboutUsHeroSection/AboutUsHeroSection";
import AboutUsOurStory from "../../components/aboutUs/aboutUsOurStory/AboutUsOurStory";
import AboutUsOurCoreValues from "../../components/aboutUs/aboutUsOurCoreValues/AboutUsOurCoreValues";
import Testimonails from "../../components/aboutUs/testimonials/Testimonials";

const AboutUs = () => {
  return (
    <>
      <AboutUsHeroSection />
      <AboutUsOurStory />
      <Testimonails />
      <AboutUsOurCoreValues />
    </>
  );
};

export default AboutUs;
