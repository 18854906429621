import React from "react";

import "./InnovationGrid.css";
import { innovationGridCardData } from "../../../helper/cardsData/homepageMockData";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const InnovationGrid = () => {
  return (
    <div className="innovation-grid-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Innovations" />
        {innovationGridCardData.map((cardData) => {
          return (
            <div
              key={cardData.id}
              className=" mt-12 lg:mt-12 lg:flex lg:items-center lg:justify-between lg:odd:flex-row-reverse"
            >
              <img
                loading="lazy"
                className="mx-auto lg:mx-0 lg:max-w-[47%] lg:flex-1"
                src={cardData.img}
                alt={cardData.heading}
              />
              <div className="lg:max-w-[47%] lg:flex-1">
                <p className="mb-3 mt-6 text-xl font-semibold text-curiousBlue  lg:my-0 lg:text-2xl">
                  {cardData.heading}
                </p>
                <p className="text-[#5B5B5B] lg:mt-4  lg:text-lg">
                  {cardData.description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InnovationGrid;
