export const logPageTitleForGA = (pageTitle) => {
  if (
    document.location.href.includes("https://agilecrafter.gemecosystem.com")
  ) {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("event", "page_view", {
      page_title: pageTitle,
    });
  }
};
