import React from "react";
import singlePlaceForQaLearningVector from "../../../assets/singlePlaceForQaLearningVector.svg";
import RevealOnScroll from "../../../common/revealOnScroll/RevealOnScroll";

const SinglePlaceForQaLearnings = () => {
  return (
    <div className="bg-curiousBlue/10 ">
      <div className=" mx-auto max-w-screen-2xl  ">
        <RevealOnScroll>
          <div className="component-padding lg:flex lg:items-center lg:justify-between ">
            <div className="lg:max-w-[47%] lg:flex-1">
              <p className="text-2xl font-semibold text-heading lg:text-[2.5rem] lg:leading-[3.125rem]">
                Gem Academy : A single place for QA Learnings!
              </p>
              <p className="mt-3 text-lg text-cello">
                An in-house academy to provide the best of the opportunities to
                sharpen skills, master competencies & expand the scope of
                professional development.
              </p>
            </div>
            <img
              className="mx-auto lg:mx-0 lg:max-w-[47%] lg:flex-1"
              src={singlePlaceForQaLearningVector}
              alt={"single Place For Qa Learning "}
            />
          </div>
        </RevealOnScroll>
      </div>
    </div>
  );
};

export default SinglePlaceForQaLearnings;
